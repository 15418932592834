import { useEffect, useState } from 'react';
import { getPoiLookup } from '@endpoints/poi';
import { ifReferFromJavars } from '@common/utils/util';
import {
  getLocation,
  postPageState,
  getPoiData as getPoiDataBridge,
} from '@common/lib/jsBridge';
import { getLsStorage, setLsStorage } from '@common/utils/localStorage';
import useTrackingStore from '@stores/tracking';
import { errorConfig } from '../helper/constant';
import {
  buildParams,
  formatLookupReponse,
  buildTrackingData,
  formatPoiTags,
  extractPoiMetaData,
} from '../helper/lookup';

export default function useLookupData(data = {}) {
  const { setLoadEventData } = useTrackingStore();

  const [loading, setLoading] = useState(true);
  const [lookupError, setLookupError] = useState(null);
  const [detailData, setDetailData] = useState({});
  const [metadata, setMetadata] = useState({});
  const [tags, setTags] = useState(null);

  const { language } = data;

  const handleErrorIfExists = res => {
    const isNoResponse = !res;
    const errObj = errorConfig();

    if (isNoResponse) {
      setLookupError(errObj.responseError);
      return !res;
    }

    const hasError = res && res.error;
    // https://grab.slack.com/archives/C0435RH09HA/p1686896464290099
    const responseIsNull = !res.result || res.result.length === 0; // The 'result' field returned by the BE API.

    if (hasError) {
      if (res.status <= 0) {
        setLookupError(errObj.networkError);
      } else {
        setLookupError(errObj.responseError);
      }
    }
    if (!res.result || res.result.length === 0) {
      setLookupError(errObj.responseError);
    }

    return !res || hasError || responseIsNull;
  };

  const requestPoiTags = async params => {
    const { parent_id: parentId, poiId } = data;

    let res = null;
    let ifGetByParent = false;
    if (parentId) {
      res = await getPoiLookup({ ...params, requested_ids: parentId });
      const { result = [] } = res || {};
      ifGetByParent = result && result.some(i => i.id === poiId);
    }
    if (!res || !ifGetByParent) {
      res = await getPoiLookup(params);
    }

    const formatTags = formatPoiTags(poiId, res);
    setTags(formatTags);

    setLsStorage('POI_DETAIL_CACHE_TAGS', {
      [data.poiId]: formatTags,
    });
    return res;
  };

  const fetchDataFromLookup = async (
    requestedId,
    latlng,
    requestLanguageCode = 'all',
  ) => {
    setLoading(true);

    const params = buildParams(data, requestedId, requestLanguageCode, latlng);

    let res = null;
    try {
      res = await getPoiLookup(params);
    } catch (error) {
      setLookupError(errorConfig().responseError);
    }

    if (handleErrorIfExists(res)) return null;

    const settingLocale = language || window.Grab.locale;
    // reference: This parameter is currently only useful in debugging; location is obtained via jsBridge method.
    const hasLatlng = data.reference || latlng;
    const formatedRes = formatLookupReponse(
      data.poiId,
      res,
      settingLocale,
      !!hasLatlng,
    );
    const metaData = extractPoiMetaData(res, data.poiId);
    setMetadata(metaData);

    requestPoiTags({
      ...params,
      language: settingLocale,
    });

    return formatedRes;
  };

  const getOTFDataFromNative = async () => {
    let res = null;
    try {
      const poiOtfData = await getPoiDataBridge({ poiId: data.poiId });
      const { combineAddress: combinedAddress, ...others } = JSON.parse(
        poiOtfData.result,
      );
      res = { combinedAddress, ...others };
    } catch (error) {
      console.log('getOTFDataFromNative error:', error);
    }
    return res;
  };

  const getDataFromCache = () => {
    if (ifReferFromJavars()) {
      const lsPoiDetailTags = getLsStorage('POI_DETAIL_CACHE_TAGS') || {};
      setTags(lsPoiDetailTags[data.poiId] || []);

      const lsPoiDetail = getLsStorage('POI_DETAIL_CACHE') || {};
      const lsPoiDetailObj = lsPoiDetail[data.poiId] || {};
      setDetailData(lsPoiDetailObj.detailData);

      return lsPoiDetailObj.detailData;
    }
    return null;
  };

  const requestDetailsData = async () => {
    try {
      // get data from cache when referrer from editing poi address page
      let res = getDataFromCache();
      if (res) return;

      if (data.is_shared_otf === 'true') {
        res = await getOTFDataFromNative(); // data from native just have limited fields
      }
      // if not shared otf, or failed to get data from native, try to get data from lookup
      if (!res) {
        const latlng = await getLocation();
        res = await fetchDataFromLookup(data.poiId, latlng);
      }

      setDetailData(res || {});

      setLsStorage('POI_DETAIL_CACHE', {
        [data.poiId]: {
          detailData: res,
        },
      });
    } catch (error) {
      setLookupError(errorConfig().responseError);
    } finally {
      setLoading(false);
    }
  };

  useEffect(
    () => {
      if (detailData) {
        // set tracking data
        const { uuid } = detailData;
        const loadEventData = buildTrackingData(detailData, metadata);
        setLoadEventData(loadEventData);
        window.PoiDetailGlobalTrackingdataAsync = {
          POI_UUID: uuid,
        };
      }
    },
    [detailData],
  );

  useEffect(() => {
    // This is for the case when the poiId from native low app version
    // https://grab.slack.com/archives/C05QXCA22DR/p1701076267534919
    if (data.poiId === 'none') {
      setLookupError(errorConfig().nonePoiIdError);
      setLoading(false);
      return;
    }
    requestDetailsData().then(() => {
      postPageState({ readyState: true });
    });
  }, []);

  return { detailData, tags, lookupError, loading };
}
