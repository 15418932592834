import React, { useRef, useEffect } from 'react';
import { isPrd } from '@common/utils/env';
import useUserStore from '@stores/user';
import { openURL } from '@common/lib/jsBridge';

import { postMessageToNative, addMarkers } from './helper';
import './index.less';

const defaultView = {
  center: {
    lat: 1.30589, // GDM compliant coordinates
    lng: 103.82109,
  },
  zoom: 16,
};

let MapboxGl = null;

const MAP_TILES_URL = isPrd()
  ? 'https://api.grab.com/map-tiles/v1/styles/mono.json'
  : 'https://api.stg-myteksi.com/map-tiles/v1/styles/mono.json';

const Map = ({ center, fallbackImage, markerIcon }) => {
  const [mapLoaded, setMapLoaded] = React.useState(false);
  const mapContainer = useRef(null);
  const mapInstance = useRef(null);
  const token = useUserStore(state => state.token);
  const gpsLocation = useUserStore(state => state.gpsLocation);

  const handleOpenUrlOutBrowser = url => {
    try {
      openURL(url);
    } catch (e) {
      console.error('openURL bridge error:', url, e);
    }
  };

  const loadMap = async () => {
    MapboxGl = await import(/* webpackChunkName: "mapbox-gl" */ '@geo/grab-maps/vendors/mapbox-gl');
    import('@geo/grab-maps/vendors/mapbox-gl/dist/mapbox-gl.css');

    // const mapUrl = await geoGrabMapStyleURLFF();

    // Start a new DataDog RUM action for map initialization
    mapInstance.current = new MapboxGl.Map({
      container: mapContainer.current,
      style: MAP_TILES_URL,
      center: center || defaultView.center,
      zoom: defaultView.zoom,
      maxZoom: 18,
      attributionControl: false,

      transformRequest: (url, type) => ({
        url,
        headers: {
          'X-MTS-SSID': token,
        },
      }),
    });

    const map = mapInstance.current;

    // disable map rotation using touch rotation gesture
    mapInstance.current.touchZoomRotate.disableRotation();

    map.on('load', () => {
      setMapLoaded(true);
      if (center && center[0] && center[1]) {
        map.jumpTo({ center });
      }

      map.addControl(
        new MapboxGl.AttributionControl({
          compact: true,
        }),
        'bottom-left',
      );

      try {
        document
          .querySelectorAll('.mapboxgl-ctrl-attrib-inner a')
          .forEach(anchor => {
            anchor.addEventListener('click', event => {
              event.preventDefault();
              const url = event.target.href;
              handleOpenUrlOutBrowser(url);
            });
          });
      } catch (e) {
        console.error('Error adding event listener to map attribution', e);
      }
    });

    map.on('error', e => {
      if (e && e.error && e.error.message) {
        console.error('Map error:', e.error || e.error.message);
      } else {
        console.error('Map error:', e);
      }
    });

    // post message to native when map is panning
    postMessageToNative(map);
  };

  useEffect(
    () => {
      if (token) {
        loadMap();
      }
    },
    [token],
  );

  useEffect(
    () => {
      if (mapLoaded && MapboxGl) {
        const { Marker } = MapboxGl;
        addMarkers(Marker, mapInstance.current, gpsLocation, center);
      }
    },
    [mapLoaded, gpsLocation, center],
  );

  return (
    <React.Fragment>
      <div ref={mapContainer} style={{ width: '100%', height: '100%' }} />
    </React.Fragment>
  );
};

export default Map;
