import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { messageContainer } from './bootstrap';
import { ErrorIcon, CloseIcon, CopySuccessIcon } from '../icons';
import './message.less';

const Message = props => {
  const {
    componentId,
    duration,
    content,
    mode,
    slideInClassName = 'slide-in',
    slideOutClassName = 'slide-out',
  } = props;
  const container = useRef(null);

  const closeMessage = () => {
    const wrapperEle = messageContainer.get(componentId);
    const parentElement = container.current && container.current.parentElement;
    ReactDOM.unmountComponentAtNode(parentElement);
    if (wrapperEle) {
      wrapperEle.remove();
    }
    messageContainer.delete(componentId);
  };

  useEffect(() => {
    const stId = setTimeout(() => {
      const parentElement = container.current;
      if (parentElement) {
        parentElement.className = parentElement.className.replace(
          slideInClassName,
          '',
        );
        parentElement.className += slideOutClassName;
        parentElement.addEventListener('animationend', () => {
          closeMessage();
        });
      }
    }, typeof duration === 'number' ? duration * 1000 : 3000);
    return () => {
      if (stId) {
        window.clearTimeout(stId);
      }
    };
  }, []);

  const renderIcon = messageType => {
    switch (messageType) {
      case 'success':
        return null;
      case 'error':
        return <ErrorIcon />;
      case 'warning':
        return null;
      case 'info':
        return <CopySuccessIcon />;
      default:
        return null;
    }
  };

  return (
    <div
      ref={container}
      className={classnames('messageContainer', mode, slideInClassName)}
      data-testid="test-message"
      style={props.styles}
    >
      <div className={classnames('message', mode)}>
        <div className="left">
          {renderIcon(mode)}
          <div className="content">{content}</div>
        </div>

        <button className="close" onClick={closeMessage}>
          <CloseIcon className="close-icon-small white" />
        </button>
      </div>
    </div>
  );
};

export default Message;
