import React, { useEffect, useMemo, useState } from 'react';
import { setPageTopBarStyle } from '@common/lib/jsBridge';
import {
  disablePoiDetailBottomBarExperiment,
  editNameRevampExperiment,
} from '@common/utils/experiments';
import ContentLoading from '@components/spinner';
import {
  ParentPoiNameIcon,
  LabelIcon,
  SavedPlaceIcon,
} from '@components/icons';
import * as message from '@components/message/bootstrap';

import PageError from '../exception';
import useFeedackAndJarvis from './hooks/useFeedackAndJarvis';
import useLookupData from './hooks/useLookupData';
import useCustomNavigate from './hooks/useCustomNavigate';
import useConditions from './hooks/useConditions';
import useTrackingDataForPoiDetail from './hooks/useTrackingData';
import {
  PoiName,
  NewPoiname,
  Nudge,
  Tags,
  AddressSection,
  DetailItem,
} from './components';
import { deserializeFormQuery } from './helper/index';
import { getMessageContext } from './helper/constant';

import styles from './index.less';

function PoiDetail() {
  const [
    editNameRevampExperimentValue,
    setEditNameRevampExperimentValue,
  ] = useState(false);
  const { navigating, setNavigateData } = useCustomNavigate();
  const searchParamsObj = deserializeFormQuery(window.location.search);
  const { poiId, is_shared_otf: isSharedOTF } = searchParamsObj;
  const { detailData = {}, tags, lookupError, loading } = useLookupData(
    searchParamsObj,
  );
  const {
    // loading: reviewLoading,
    requestDone: requestFeedbackAndJarvisDone,
    feedbackUuid,
    reviewStatusAndNudge,
    refreshFeedackAndJarvis,
  } = useFeedackAndJarvis({ poiId, isSharedOTF });
  const isChildPoi = useMemo(() => !!detailData.parentName, [
    detailData.parentName,
  ]);
  const conditions = useConditions(reviewStatusAndNudge, isChildPoi, poiId);
  useTrackingDataForPoiDetail(
    searchParamsObj,
    requestFeedbackAndJarvisDone,
    conditions,
  );

  useEffect(() => {
    disablePoiDetailBottomBarExperiment();
    // 0：show left icon and right icon
    // 1：hidden
    // 2：show left icon
    setPageTopBarStyle({ style: 0 });

    // show success message after submitting address edit
    if (searchParamsObj.javisSubmitSuccess > 0) {
      message.success({ content: getMessageContext('success') });
    }
    editNameRevampExperiment().then(value => {
      setEditNameRevampExperimentValue(value);
    });
  }, []);

  if (loading) {
    return <ContentLoading />;
  }
  if (lookupError) {
    return <PageError config={lookupError} />;
  }
  if (navigating) {
    return null;
  }

  return (
    <div
      data-testid="poi-detail-container"
      className={styles.poiDetailContainer}
    >
      {!editNameRevampExperimentValue ? (
        <PoiName
          poiId={poiId}
          name={detailData.name}
          transName={detailData.transName}
          isChildPoi={isChildPoi}
          refreshFeedackAndJarvis={refreshFeedackAndJarvis}
          showNameWarning={conditions.showNameWarning}
          showNameInReview={conditions.showNameInReview}
          showEditButton={conditions.showEditButton}
        />
      ) : (
        <NewPoiname
          poiId={poiId}
          name={detailData.name}
          transName={detailData.transName}
          isChildPoi={isChildPoi}
          showNameWarning={conditions.showNameWarning}
          showNameInReview={conditions.showNameInReview}
          showEditButton={conditions.showEditButton}
        />
      )}

      {conditions.showNudges && (
        <Nudge
          poiId={poiId}
          feedbackUuid={feedbackUuid}
          feedbackPrompts={reviewStatusAndNudge && reviewStatusAndNudge.nudges}
        />
      )}

      {/* address */}
      <AddressSection
        detailData={detailData}
        setNavigateData={setNavigateData}
        showAddressInReview={conditions.showAddressInReview}
        showLocationWarning={conditions.showLocationWarning}
        showEditButton={conditions.showEditButton}
      />

      {/* saved place */}
      {detailData.label && (
        <DetailItem
          label={detailData.label}
          icon={<SavedPlaceIcon className="saved-place-icon" />}
        />
      )}

      {/* parent poi Name */}
      {detailData.parentName ? (
        <DetailItem
          label={detailData.parentName}
          icon={<ParentPoiNameIcon className="parent-poiName-icon" />}
        />
      ) : null}

      {/* tags */}
      {tags && tags.length > 0 ? (
        <DetailItem icon={<LabelIcon className="label-icon" />}>
          <div className="tags">
            {tags.map((tag = {}) => <Tags key={tag.code} {...tag} />)}
          </div>
        </DetailItem>
      ) : null}
    </div>
  );
}

export default PoiDetail;
