/* eslint-disable import/prefer-default-export */
import {
  geoPOIListTabFallbackIndexFF,
  showPageBottomBar,
  trackEventForPoiDetail,
} from '@common/lib/jsBridge';
import { safeJSONParse } from '@common/utils/util';

export const disablePoiDetailBottomBarExperiment = async () => {
  let poiDetailDisableBottomButton = false;
  try {
    const result = await geoPOIListTabFallbackIndexFF();
    const value = result ? safeJSONParse(result) : {};

    poiDetailDisableBottomButton = value.poiDetailDisableBottomButton;

    if (value.poiDetailDisableBottomButton) {
      showPageBottomBar({ needShow: false });
    } else {
      showPageBottomBar({ needShow: true });
    }
  } catch (error) {
    console.error('Error while getting geoPOIListTabFallbackIndex:', error);
    showPageBottomBar({ needShow: true });
    poiDetailDisableBottomButton = true;
  } finally {
    trackEventForPoiDetail({
      name: 'DISABLE_BOTTOM_BAR',
      params: {
        STATE_NAME: 'PLACE_DETAILS',
        DISABLE_BOTTOM_BAR: poiDetailDisableBottomButton,
      },
    });
  }
};

export const editNameRevampExperiment = async () => {
  try {
    const result = await geoPOIListTabFallbackIndexFF();
    const value = result ? safeJSONParse(result) : {};
    return value.poiDetailEditNameRevamp;
  } catch (error) {
    console.error('Error while getting geoPOIListTabFallbackIndex:', error);
    return false;
  }
};
