import React, { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import PoiDetail from '@pages/poi-detail';

const WebviewDebug = lazy(() => import('@pages/debug/WebviewDebug'));
const Exception = lazy(() => import('@pages/exception'));

function AppRoutes() {
  const routes = [
    {
      path: 'debug',
      element: <WebviewDebug />,
    },
    {
      path: 'exception',
      element: <Exception />,
    },
    {
      path: 'detail',
      element: <PoiDetail />,
    },
  ];

  const routing = useRoutes(routes);
  return <React.Fragment>{routing}</React.Fragment>;
}

export default AppRoutes;
