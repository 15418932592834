export const DEFAULT_CACHE_TIME = 7 * 24 * 60 * 60 * 1000; // 7 days

export function setLsStorage(key, val) {
  if (val) {
    localStorage.setItem(
      key,
      JSON.stringify({
        data: val,
        createTime: Date.now(),
      }),
    );
  }
}

export function removeLsStorage(key) {
  localStorage.removeItem(key);
}

export function getLsStorage(key) {
  try {
    const res = JSON.parse(localStorage.getItem(key)) || {};
    const { createTime, data } = res;
    if (createTime && Date.now() - createTime <= DEFAULT_CACHE_TIME) {
      return data;
    }
    removeLsStorage(key);
    return null;
  } catch (e) {
    return null;
  }
}
