import React, { useEffect } from 'react';
import i18n from 'i18n-js';
import isSupported from 'mapbox-gl-supported';
import classNames from 'classnames';
import * as message from '@components/message/bootstrap';
import { getMycUrl } from '@common/utils/env';

import { datadogRum } from '@datadog/browser-rum';

import {
  showPageBottomBar,
  setPageTopBarStyle,
  trackEventForPoiDetail,
} from '@common/lib/jsBridge';
import { copyTextToClipboard } from '@common/utils/util';
import {
  AddressIcon,
  EditIcon,
  InReviewIcon,
  PinIcon,
  CopyIcon,
} from '@components/icons';
import mapDefault from 'public/images/map-default.png';
import WarningTip from './WarningTip';
import DetailItem from './DetailItem';
import Map from './MapboxMap/index';
import styles from './addressSection.less';

const AddressSection = ({
  detailData,
  showAddressInReview,
  showLocationWarning,
  showEditButton,
  setNavigateData,
}) => {
  const { addressData, latlng, combinedAddress } = detailData || {};
  const center = latlng && [latlng.longitude, latlng.latitude];

  window.isMapboxWebGlSupported = isSupported();

  useEffect(() => {
    if (!window.isMapboxWebGlSupported) {
      datadogRum.addAction('Unsupported Browser', {
        message: 'Mapbox GL is not supported on this browser',
      });
    }
  }, []);

  const handleEditAddress = () => {
    const mycUrl = getMycUrl(window.Grab.locale);
    const paramsData = `${window.location.search}&address=${encodeURIComponent(
      JSON.stringify(addressData || {}),
    )}`;
    const jarvisUrl = `${mycUrl}${paramsData}`;

    try {
      trackEventForPoiDetail({
        name: 'EDIT_ADDRESS_AND_LOCATION_CLICK',
        params: {
          HAS_SUGGEST_EDIT_PROMPT: showLocationWarning,
        },
      });
    } catch (err) {
      // handle error
    }

    // 0：显示左侧icon和右边的icon
    // 1：隐藏
    // 2：只显示左侧icon
    setNavigateData({
      url: jarvisUrl,
      replace: true,
      callback: () => {
        showPageBottomBar({ needShow: false });
        setPageTopBarStyle({ style: 1 });
      },
    });
  };

  const handleCopy = () => {
    const { name, combined_address: _address } = addressData || {};
    const copyText =
      name && _address ? `${name} • ${_address}` : name || _address;

    trackEventForPoiDetail({
      name: 'COPY_ADDRESS',
      params: {
        STATE_NAME: 'PLACE_DETAILS',
      },
    });

    copyTextToClipboard(copyText, () => {
      message.info({
        content: i18n.t('geo_sharing_place_address_has_been_copied'),
        duration: 3,
      });
      trackEventForPoiDetail({
        name: 'COPY_ADDRESS_SUCCESS',
        params: {
          STATE_NAME: 'PLACE_DETAILS',
        },
      });
    });
  };

  return (
    <div className={classNames(styles.addressWrapper, 'device-border-bottom')}>
      <DetailItem
        label={combinedAddress || ''}
        icon={<AddressIcon className="address-icon" />}
        borderBottom={0}
        extra={
          <div>
            <button className={styles.copyBtn} onClick={handleCopy}>
              <CopyIcon className="copy-icon" />
            </button>
            {showEditButton && (
              <button
                onClick={handleEditAddress}
                disabled={showAddressInReview}
                data-testid="address-edit-button"
              >
                <EditIcon
                  className={classNames(
                    'edit-icon',
                    showAddressInReview ? 'disabled' : '',
                  )}
                />
              </button>
            )}
          </div>
        }
      />
      {window.isMapboxWebGlSupported ? (
        <div className={styles.mapWrapper}>
          <Map
            center={center}
            fallbackImage={mapDefault}
            markerIcon={<PinIcon />}
          />
        </div>
      ) : (
        <div>
          <img
            alt="default_map"
            src={mapDefault}
            style={{ width: '100%', background: '#f8f8f8;' }}
          />
        </div>
      )}
      {showAddressInReview && (
        <div className="in-review-line" data-testid="address-in-review">
          <InReviewIcon className="inReview-icon" />
          <span>
            {i18n.t(
              'geo_places_search_revamp_metadata_edits_in_review_edit_saved_place',
            )}
          </span>
        </div>
      )}
      {showLocationWarning && <WarningTip />}
    </div>
  );
};

export default AddressSection;
