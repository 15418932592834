import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import i18n from 'i18n-js';
import classNames from 'classnames';
import { trackEventForPoiDetail } from '@common/lib/jsBridge';
import * as message from '@components/message/bootstrap';
import { EditIcon, CloseIcon, InReviewIcon, UpIcon } from '@components/icons';

import WarningTip from './WarningTip';
import useSubmissionSubmit from '../hooks/useJarvisSubmission';
import { getMessageContext } from '../helper/constant';

import styles from './poiName.less';

export default function PoiName(props) {
  const { showNameWarning, showNameInReview, showEditButton = false } = props;
  const [editing, setEditing] = useState(false);
  const [isChangeName, setIsChangeName] = useState(false);
  const [changedName, setChanedName] = useState();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const inputRef = useRef(null);

  const { postEditPoiName, loading: submitLoading } = useSubmissionSubmit({
    poiId: props.poiId,
  });

  // Refresh in-review status when submit success.
  const showNameInReviewAndSubmitSuccess = useMemo(
    () => showNameInReview || submitSuccess,
    [showNameInReview, submitSuccess],
  );

  useEffect(
    () => {
      setChanedName(props.name);
    },
    [props.name],
  );

  useEffect(
    () => {
      if (editing) {
        setChanedName(props.name);
        inputRef.current.focus();
      }
      if (!editing) {
        setIsChangeName(false);
      }
    },
    [editing],
  );

  const handleClickOutside = useCallback(
    event => {
      if (!editing) return;
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setEditing(false);
      }
    },
    [editing],
  );

  useEffect(
    () => {
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    },
    [handleClickOutside],
  );

  const handleEdit = () => {
    setEditing(!editing);

    // tracking data
    trackEventForPoiDetail({
      name: 'EDIT_PLACE_NAME_CLICK',
      params: {
        HAS_SUGGEST_EDIT_PROMPT: showNameWarning,
      },
    });
  };

  const handleSubmit = async e => {
    e.stopPropagation();
    const locale = window.Grab.locale;
    const res = await postEditPoiName(
      {
        [locale || 'en']: changedName,
      },
      props.isChildPoi,
    );

    const ifSuccess = res && res.status_code === 0;
    if (ifSuccess) {
      message.success({ content: getMessageContext('success') });
      // refresh the status of edit button and in-review status in frontend
      setSubmitSuccess(true);
      setEditing(false);
    } else {
      message.error({ content: getMessageContext('error') });
    }

    try {
      trackEventForPoiDetail({
        name: 'EDIT_PLACE_NAME_SUBMIT',
      });
      trackEventForPoiDetail({
        name: 'EDIT_SUBMISSION_TOAST_LOAD',
        params: {
          SUBMISSION_STATUS: ifSuccess ? 'SUCCESS' : 'FAIL',
          EDIT_FIELD: 'NAME',
          SUBMISSION_ID: ifSuccess ? res.data.output.submission_id : null,
        },
      });
    } catch (error) {
      // handle error for track data failed.
    }
  };

  const handleChangeName = useCallback(e => {
    setChanedName(e.target.value);
    setIsChangeName(true);
  }, []);

  return (
    <div className={classNames(styles.container, 'device-border-bottom')}>
      {!editing ? (
        <React.Fragment>
          <div className={styles.poiName} data-testid="name-display">
            <span className="line-clamp">{props.name}</span>
            {showEditButton && (
              <button
                data-testid="name-edit-button"
                className={styles.editBtn}
                onClick={handleEdit}
                disabled={showNameInReviewAndSubmitSuccess}
              >
                <EditIcon
                  className={classNames(
                    'edit-icon',
                    showNameInReviewAndSubmitSuccess ? 'disabled' : '',
                  )}
                />
              </button>
            )}
          </div>
          <div className={styles.subTitle}>
            <span className="line-clamp">{props.transName}</span>
          </div>
          {!showNameInReviewAndSubmitSuccess &&
            showNameWarning && <WarningTip />}
          {showNameInReviewAndSubmitSuccess && (
            <div className="in-review-line" data-testid="name-in-review">
              <InReviewIcon className="inReview-icon" />
              <span>
                {i18n.t(
                  'geo_places_search_revamp_metadata_edits_in_review_edit_saved_place',
                )}
              </span>
            </div>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className={styles.edit} data-testid="name-edit">
            <div className={styles.left}>
              <input
                type="text"
                ref={inputRef}
                value={changedName}
                onChange={handleChangeName}
                disabled={submitLoading}
              />
              {!submitLoading &&
                isChangeName &&
                changedName && (
                  <button
                    data-testid="name-submit-button"
                    className={styles.circleBtn}
                    onClick={handleSubmit}
                  >
                    <UpIcon className="up-icon" />
                  </button>
                )}
            </div>

            <button onClick={handleEdit} data-testid="name-edit-close-button">
              <CloseIcon className="close-icon" />
            </button>
          </div>
          <span className={styles.extraText}>
            {i18n.t(
              'geo_search_revamp_tooltip_keep_info_updated_edit_loc_details',
            )}
          </span>
        </React.Fragment>
      )}
    </div>
  );
}
