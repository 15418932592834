import React, { useEffect, useState } from 'react';
import { getAccessToken, getLocation } from '../common/lib/jsBridge';
import PageError from '../pages/exception';
import { errorConfig } from '../pages/poi-detail/helper/constant';
import useUserStore from '../stores/user';

function ProtectedRoutes({ children }) {
  const { setToken, setGpsLocation } = useUserStore();
  const [tokenError, setTokenError] = useState(null);

  const getGpsLocation = async () => {
    const res = await getLocation();
    setGpsLocation(res);
  };

  const getToken = async () => {
    let accessToken = '';
    const urlParams = new URLSearchParams(window.location.search);
    accessToken = urlParams.get('token');
    if (accessToken) {
      setToken(accessToken);
      return;
    }

    const { accessToken: bridgeAccessToken, isInApp } =
      (await getAccessToken()) || {};
    if (!bridgeAccessToken) {
      const errObj = errorConfig();
      setTokenError(isInApp ? errObj.responseError : errObj.noTokenError);
      console.error('No access token found in App');
      return;
    }
    setToken(bridgeAccessToken);
  };

  useEffect(() => {
    getToken();
    getGpsLocation();
  }, []);

  if (tokenError) {
    return <PageError config={tokenError} />;
  }
  return children;
}

export default ProtectedRoutes;
