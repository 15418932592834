import { postPageState } from '@common/lib/jsBridge';

import gpsSvg from 'public/images/gps-avatar.svg';
import pinSvg from 'public/images/pin.svg';

const icons = {
  pin: {
    url: pinSvg,
    width: '1.65rem',
    height: '1.85rem',
  },
  gps: {
    url: gpsSvg,
    width: '2.2rem',
    height: '2.2rem',
  },
};

export const getMarkerEl = type => {
  const { url, width, height } = icons[type];
  const el = document.createElement('div');
  el.className = 'marker';
  el.style.backgroundImage = `url(${url})`;
  el.style.width = width;
  el.style.height = height;
  el.style.backgroundSize = 'contain';
  el.style.backgroundRepeat = 'no-repeat';

  return el;
};

export const addMarkers = (Marker, map, gpsLocation, center) => {
  if (gpsLocation && gpsLocation.longitude && gpsLocation.latitude) {
    const el = getMarkerEl('gps');
    new Marker({ element: el, anchor: 'bottom' })
      .setLngLat([gpsLocation.longitude, gpsLocation.latitude])
      .addTo(map);
  }
  if (center && center[0] && center[1]) {
    const el = getMarkerEl('pin');
    new Marker({ element: el, anchor: 'bottom' }).setLngLat(center).addTo(map);
  }
};

export const postMessageToNative = map => {
  const parentElement = map.getContainer();
  parentElement.addEventListener(
    'touchmove',
    () => {
      postPageState({ mapOnPan: true });
    },
    { passive: false },
  );
  parentElement.addEventListener(
    'touchend',
    () => {
      postPageState({ mapOnPan: false });
    },
    { passive: false },
  );
};
