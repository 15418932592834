import React, { useState, useMemo } from 'react';
import i18n from 'i18n-js';
import classNames from 'classnames';
import { InReviewIcon } from '@components/icons';
import ContentLoading from '@components/spinner';

import { trackEventForPoiDetail } from '@common/lib/jsBridge';
import * as message from '@components/message/bootstrap';

import EditNameModal from './index';
import useAsyncModal from './useAsyncmodal';
import SuggestAnEdit from '../SuggestAnEdit';
import { getMessageContext } from '../../helper/constant';

import styles from './poiName.less';

export default function PoiName(props) {
  const { showNameWarning, showNameInReview } = props;
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const { modalVisible, isLoading, openModal, closeModal } = useAsyncModal();

  // Refresh in-review status when submit success.
  const showNameInReviewAndSubmitSuccess = useMemo(
    () => showNameInReview || submitSuccess,
    [showNameInReview, submitSuccess],
  );

  const handleClickEdit = async () => {
    // hidden top bar and bottom bar
    openModal();

    // tracking data
    trackEventForPoiDetail({
      name: 'EDIT_PLACE_NAME_CLICK',
      params: {
        HAS_SUGGEST_EDIT_PROMPT: showNameWarning,
      },
    });
  };

  const handleSubmitSuccess = async () => {
    await closeModal();
    setSubmitSuccess(true); // Refresh in-review status.
    message.success({ content: getMessageContext('success') });
  };

  const handleCloseEditModal = async () => {
    closeModal();
  };

  return (
    <React.Fragment>
      <div className={classNames(styles.container, 'device-border-bottom')}>
        <React.Fragment>
          <div className={styles.poiName} data-testid="name-display">
            <span className="line-clamp">{props.name}</span>
          </div>
          <div className={styles.subTitle}>
            <span className="line-clamp">{props.transName}</span>
          </div>
          {!showNameInReviewAndSubmitSuccess && (
            <SuggestAnEdit onClick={handleClickEdit} />
          )}
          {showNameInReviewAndSubmitSuccess && (
            <div className="in-review-line" data-testid="name-in-review">
              <InReviewIcon className="inReview-icon" />
              <span>
                {i18n.t(
                  'geo_places_search_revamp_metadata_edits_in_review_edit_saved_place',
                )}
              </span>
            </div>
          )}
        </React.Fragment>
      </div>
      {isLoading && (
        <div className={styles.loading}>
          <ContentLoading />
        </div>
      )}

      <EditNameModal
        name={props.name}
        poiId={props.poiId}
        isChildPoi={props.isChildPoi}
        visible={modalVisible}
        onClose={handleCloseEditModal}
        onSubmitSuccess={handleSubmitSuccess}
      />
    </React.Fragment>
  );
}
