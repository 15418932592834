import ReactDOM from 'react-dom';
import React from 'react';
import Message from './message';

let i = 1;
const idGenerator = () =>
  // eslint-disable-next-line no-plusplus
  `lemon-message-no-${i++}`;

// 组件id与容器的映射关系
export const messageContainer = new Map();

export const createMessage = (options, mode) => {
  const element = document.createElement('div');
  const componentId = idGenerator();
  messageContainer.set(componentId, element);
  const instance = <Message {...{ ...options, componentId, mode }} />;
  document.body.appendChild(element);
  ReactDOM.render(instance, element);
};

export const success = options => {
  createMessage(options, 'success');
};

export const error = options => {
  createMessage(options, 'error');
};

export const warning = options => {
  createMessage(options, 'warning');
};

export const info = options => {
  createMessage(options, 'info');
};

export default {
  success,
  error,
  warning,
  info,
};
