/* eslint-disable import/first */
import React, { useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import i18n from 'i18n-js';
import { trackEventForPoiDetail } from '@common/lib/jsBridge';
import { Swiper, SwiperSlide } from '@components/new-swiper';
import * as message from '@components/message/bootstrap';
import { NudgeIcon, NudgeRejectIcon, NudgeAcceptIcon } from '@components/icons';
import useSubmissionSubmit from '../hooks/useJarvisSubmission';
import { feedbackAnswer } from '../helper/constant';

import styles from './nudge.less';

const removePromptByCategory = (prompts, targetCategory) =>
  prompts.filter(prompt => prompt.category !== targetCategory);

const displayErrorMessage = () =>
  message.error({
    content: i18n.t('geo_places_search_revamp_toast_couldnt_save_edits'),
  });

export default function Nudge(props) {
  const { feedbackPrompts = [] } = props;
  const { poiId } = props;
  const [prompts, setPrompts] = useState([]);
  const [submitInfo, setSubmitInfo] = useState({ key: '', loading: false });
  const { postFeedback } = useSubmissionSubmit({ poiId });

  const isPagination = useMemo(() => prompts && prompts.length > 1, [prompts]);

  useEffect(
    () => {
      if (feedbackPrompts && feedbackPrompts.length > 0) {
        setPrompts([...feedbackPrompts]);
      }
    },
    [feedbackPrompts],
  );

  useEffect(
    () => {
      if (prompts && prompts.length) {
        try {
          trackEventForPoiDetail({
            name: 'FEEDBACK_NUDGE_CARD_LOAD',
            params: {
              NUDGE_QUESTION_COUNT: prompts.length,
              NUDGE_CATEGORY: prompts.map(i => i.category).join(','),
              NUDGE_UUID: props.feedbackUuid,
            },
          });
        } catch (error) {
          // handle error
        }
      }
    },
    [prompts],
  );

  const handleChangeSwiper = currItem => {
    try {
      trackEventForPoiDetail({
        name: 'FEEDBACK_NUDGE_CARD_SWIPE',
        params: {
          NUDGE_ORDER: currItem + 1,
          NUDGE_CATEGORY: prompts[currItem].category,
          NUDGE_UUID: props.feedbackUuid,
        },
      });
    } catch (error) {
      // handle error
    }
  };

  const handleClick = async (userOption, qa, indx) => {
    let isSuccess = false;
    try {
      setSubmitInfo({ key: qa.category, loading: true, userOption });

      const res = await postFeedback({ ...qa, userOption });

      const { status_code: statusCode, message: responseMsg } = res || {};
      if (statusCode === 0 && responseMsg.toLowerCase() === 'success') {
        const filteredPrompts = removePromptByCategory(prompts, qa.category);
        setPrompts([...filteredPrompts]);
        setSubmitInfo({});
        isSuccess = true;
      } else {
        throw new Error();
      }
    } catch (err) {
      setSubmitInfo({});
      displayErrorMessage();
      isSuccess = false;
    }

    try {
      trackEventForPoiDetail({
        name: 'FEEDBACK_NUDGE_CARD_ANSWERED',
        params: {
          NUDGE_ORDER: indx + 1,
          NUDGE_CATEGORY: qa.category,
          NUDGE_UUID: props.feedbackUuid,
          RESULT: isSuccess ? 'SUCCESS' : 'FAILURE',
        },
      });
    } catch (error) {
      // handle error
      console.error('FEEDBACK_NUDGE_CARD_ANSWERED error:', error);
    }
  };

  return prompts && prompts.length > 0 ? (
    <div className={classnames(styles.container, 'device-border-bottom')}>
      <div className={classnames(styles.swiperWrapper)}>
        <div
          className={styles.leftIcon}
          style={{
            paddingBottom: isPagination ? '.8rem' : '',
          }}
        >
          <NudgeIcon className="nudge-icon" />
        </div>
        <Swiper
          spaceBetween={50}
          pagination={isPagination}
          wrapperStyle={{ marginLeft: '2.2rem' }}
          paginationStyle={{
            paddingRight: '2.2rem',
          }}
          onChange={handleChangeSwiper}
        >
          {prompts.map(
            (qa, indx) =>
              qa.question && (
                <SwiperSlide key={indx}>
                  <div className={styles.qaItem}>
                    <span className="line-clamp">{qa.question}</span>
                    <span className={styles.answers}>
                      <button
                        data-testid="nudge-accept-button"
                        onClick={() =>
                          handleClick(feedbackAnswer.ACCEPT, qa, indx)
                        }
                      >
                        {
                          <NudgeAcceptIcon
                            className={classnames(
                              'nudge-accept-icon',
                              submitInfo.userOption === feedbackAnswer.ACCEPT &&
                              submitInfo.loading === true
                                ? 'submitting'
                                : '',
                            )}
                          />
                        }
                      </button>
                      <button
                        data-testid="nudge-reject-button"
                        onClick={() =>
                          handleClick(feedbackAnswer.REJECT, qa, indx)
                        }
                      >
                        {
                          <NudgeRejectIcon
                            className={classnames(
                              'nudge-reject-icon',
                              submitInfo.userOption === feedbackAnswer.REJECT &&
                              submitInfo.loading === true
                                ? 'submitting'
                                : '',
                            )}
                          />
                        }
                      </button>
                    </span>
                  </div>
                </SwiperSlide>
              ),
          )}
        </Swiper>
      </div>
    </div>
  ) : null;
}
