import React, { useRef, useState, useCallback } from 'react';
import classNames from 'classnames';
import { InputDelete } from '@components/icons';

import styles from './index.less';

const Input = props => {
  const { defaultValue, onChange, status } = props;
  const inputRef = useRef(null);
  const [showSuffix, setShowSuffix] = useState(false);
  const [changedName, setChanedName] = useState(defaultValue);

  const handleChangeName = useCallback(e => {
    const value = e.target.value;
    setChanedName(e.target.value);
    if (onChange) {
      onChange(value);
    }
  }, []);

  const handleClear = () => {
    setChanedName('');
    if (onChange) {
      onChange('');
    }
  };

  return (
    <div className={styles.inputContainer}>
      <input
        className={classNames(
          styles.textInput,
          { [styles.error]: status === 'error' },
          { [styles.active]: status === 'active' },
        )}
        type="text"
        ref={inputRef}
        value={changedName}
        onChange={handleChangeName}
        onFocus={() => setShowSuffix(true)}
        onBlur={() => setShowSuffix(false)}
        // disabled={submitLoading}
      />
      {changedName && (
        <button
          data-testid="input-clear-button"
          className={classNames('icon-btn', styles.suffix, {
            [styles.error]: status === 'error',
          })}
          style={{ display: showSuffix ? 'block' : 'none' }}
          onMouseDown={handleClear}
        >
          <InputDelete />
        </button>
      )}
    </div>
  );
};

export default Input;
