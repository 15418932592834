// @ts-check
import { feedbackCategory, getFeedbackContext } from '../helper/constant';

/**
 * @typedef {Object} Prompt
 * @property {string} Category
 * @property {string} possibleBetterValue
 * @property {string[]} possibleBetterValueMulti
 */

/**
 *
 * @param {string[]} values
 * @returns {{[key: string]: string}|null}
 *
 * @example
 * returns { en: 'English', zh: 'Chinese' }
 * parsePossibleBetterValueMulti(['en=English', 'zh=Chinese'])
 */
const parsePossibleBetterValueMulti = values => {
  if (!values || values.length === 0) return null;
  return values.reduce((result, item) => {
    const [key, value] = item.split(/=(.+)/);
    return { ...result, [key]: value };
  }, {});
};

/**
 * @typedef {Object} EnhancedPrompt
 * @property {string} category
 * @property {string|null} question
 *
 * Enhances a prompt with a fully formed question.
 *
 * @param {Prompt} prompt
 * @param {string} locale  - Locale for formatting.
 * @returns {EnhancedPrompt|null}  - Enhanced Prompt or null if process fails.
 */
export const processPrompt = (prompt, locale) => {
  const { Category: category, possibleBetterValue, possibleBetterValueMulti } =
    prompt || {};

  try {
    let question = getFeedbackContext(category);
    if (!question) return null;

    const isAlias = category === feedbackCategory.ALIAS;
    if (isAlias && possibleBetterValue) {
      question = question.replace('%s', possibleBetterValue);
    }
    const isTranslationName = category === feedbackCategory.TRANSLATED_NAME;
    if (isTranslationName && possibleBetterValueMulti) {
      const parsedValue =
        parsePossibleBetterValueMulti(possibleBetterValueMulti) || {};
      const translatedName = parsedValue[locale];
      question = translatedName && question.replace('%s', translatedName);
    }

    return {
      category,
      question,
    };
  } catch (error) {
    return {
      category,
      question: null,
    };
  }
};

export const buildNudges = (unAnsweredPrompts = [], options = {}) => {
  if (!unAnsweredPrompts || unAnsweredPrompts.length === 0) return [];
  const nudges = unAnsweredPrompts.map(prompt =>
    processPrompt(prompt, options.locale),
  );
  const filteredNudges = nudges.filter(nudge => nudge && nudge.question);
  return filteredNudges;
};

export const doesCategoryExistInFeedbacks = (category, feedbacks) =>
  feedbacks.some(({ question_id: questionId }) => questionId === category);

export const filterFeedbackPrompt = (prompts, feedbacks) => {
  if (!feedbacks) return prompts;

  return (
    prompts &&
    prompts.reduce((acc, prompt) => {
      const { Category } = prompt;
      if (!doesCategoryExistInFeedbacks(Category, feedbacks)) {
        acc.push(prompt);
      }
      return acc;
    }, [])
  );
};

/**
 * @typedef {Object} Address
 * @property {string} street_id
 * @property {string} block
 * @property {string} unit
 * @property {string} postcode
 * @property {{[key: string]: string}} street
 * @property {string} building
 * @property {string} house_no
 *
 * @typedef {Object} Poi
 * @property {number} latitude
 * @property {number} longitude
 * @property {Address} address
 * @property {{[key: string]: string}} name
 * @property {string} short_name
 *
 * @typedef {Object} ReviewResult
 * @property {number} status
 * @property {string} comment
 *
 * @typedef {Object} Feedback
 * @property {string} question_id
 * @property {string} question_title
 * @property {number} user_option
 *
 * @typedef {Object} Submission
 * @property {string} poi_id
 * @property {number} submission_type
 * @property {ReviewResult} review_result
 * @property {Poi} [poi]
 * @property {Feedback[]} [feedbacks]
 *
 * @typedef {Object} ReviewStatus
 * @property {number} shortNameReviewStatus
 * @property {number} nameReviewStatus
 * @property {number} addressReviewStatus
 * @typedef {Object} Nudge
 * @property {string} category
 * @property {string} question
 * @param {{ submissions: Submission[], prompts: Prompt[], options: {[key: string]: any}  }} param
 * @returns {{reviewStatus: ReviewStatus, nudges: Nudge[] } | {}}}
 */
export const processReviewStatusAndNudges = ({
  submissions,
  prompts: promptsFromHeu,
  options,
}) => {
  let unAnsweredPrompts = promptsFromHeu || [];
  const reviewStatus = {};

  if (submissions && submissions.length > 0) {
    submissions.forEach(list => {
      if (!list) return;

      const {
        poi,
        feedbacks: answeredFeedbacks,
        review_result: reviewResult,
      } = list;
      const hasEditShortName = poi && poi.short_name;
      const hasEditName = poi && poi.name;
      const hasEditAddress =
        poi && (poi.address || poi.latitude || poi.longitude);

      if (hasEditShortName) {
        reviewStatus.shortNameReviewStatus = reviewResult.status;
      }
      if (hasEditName) {
        reviewStatus.nameReviewStatus = reviewResult.status;
      }
      if (hasEditAddress) {
        reviewStatus.addressReviewStatus = reviewResult.status;
      }
      unAnsweredPrompts = filterFeedbackPrompt(
        promptsFromHeu,
        answeredFeedbacks,
      );
    });
  }

  if (!unAnsweredPrompts) return reviewStatus;

  return {
    ...reviewStatus,
    nudges: buildNudges(unAnsweredPrompts, options),
  };
};
