import { create } from 'zustand';

const useTrackingStore = create((set, get) => ({
  loadEventData: {},
  setLoadEventData: data => {
    const currentLoadEventData = get().loadEventData;
    set({ loadEventData: { ...currentLoadEventData, ...data } });
  },
}));

export default useTrackingStore;
