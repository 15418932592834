import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18n-js';
import classNames from 'classnames';
import PrimaryButton from '@components/button/primary';

import styles from './index.less';

function ConfirmDialog({ title, content, onConfirm, onCancel }) {
  return (
    <div className={styles.confirmDialog}>
      <div className={styles.confirmDialogBox}>
        <div className={classNames(styles.title, 'line-clamp')}>{title}</div>
        <div className={classNames(styles.content, 'line-clamp')}>
          {content}
        </div>
        <div className={styles.footer}>
          <PrimaryButton
            onClick={onCancel}
            style={{
              background: '#EEF9F9',
              backgroundColor: '#EEF9F9',
              color: '#184440',
            }}
          >
            {i18n.t('geo_name_validation_button_no')}
          </PrimaryButton>
          <PrimaryButton onClick={onConfirm}>
            {i18n.t('geo_name_validation_button_yes')}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

// eslint-disable-next-line import/prefer-default-export
export function useConfirmationDialog() {
  const [confirmationDialog, setConfirmationDialog] = useState(null);

  const confirm = (title, content, onConfirm, onCancel) => {
    setConfirmationDialog({ title, content, onConfirm, onCancel });
  };

  const close = () => setConfirmationDialog(null);

  const ConfirmationDialogPortal = confirmationDialog
    ? ReactDOM.createPortal(
        <ConfirmDialog
          title={confirmationDialog.title}
          content={confirmationDialog.content}
          onConfirm={() => {
            confirmationDialog.onConfirm();
            close();
          }}
          onCancel={() => {
            confirmationDialog.onCancel();
            close();
          }}
        />,
        document.body,
      )
    : null;

  return { confirm, ConfirmationDialogPortal };
}
