import { get } from '@common/utils/service';
import { isPrd, isDev, isStg, isMock } from '@common/utils/env';
import { datadogRum } from '@datadog/browser-rum';
import mockFeedbackPrompt from './mock/feedbackPrompt.json';
// import mockLookup from './mock/lookup.json';

const getBaseUrl = () => {
  const POI_HOST = isPrd()
    ? 'https://p.grabtaxi.com'
    : 'https://p.stg-myteksi.com';
  return `${POI_HOST}/api/passenger/v2/poi`;
};

export const getPoiLookup = async params => {
  let uri = `${getBaseUrl()}/lookup`;
  if (isMock() || isDev()) {
    uri = 'https://poi-int.stg-myteksi.com/services/poi/lookup';
    const res = await get(uri, params);
    return res;
  }
  const res = await get(uri, params);

  if (isStg()) {
    try {
      datadogRum.addAction('poi_lookup', {
        uri,
        params,
      });
    } catch (err) {
      // eslint-disable-next-line no-empty
    }
  }

  return res;
};

export const getPoiFeedbackPrompt = async params => {
  const { poiId } = params;
  const baseUrl = getBaseUrl();

  if (isMock() || isDev()) {
    return Promise.resolve(mockFeedbackPrompt);
  }
  // status code 400 response_data: {"target":"","reason":"invalid_argument","message":"dto.GetPoiFeedbackPromptRequest is invalid: map[PoiID:[string should have no more than 16 characters, instead it has 38]]"}
  if (!poiId || poiId.length > 16) {
    return null;
  }
  try {
    const res = await get(`${baseUrl}/get_poi_feedback_prompt`, {
      poiId,
    });
    return res;
  } catch (error) {
    return null;
  }
};
