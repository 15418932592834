import React from 'react';
import i18n from 'i18n-js';
import { WarningIcon } from '@components/icons';

import styles from './suggestAnEdit.less';

export default function WarningTip(props) {
  const { onClick } = props;
  return (
    <div className={styles.suggestAnEditContainer}>
      <div className={styles.content}>
        <WarningIcon className="warning-icon" />
        <span className={styles.text}>
          {i18n.t('geo_name_validation_body_know_accurate_info')}
        </span>
      </div>
      <button className={styles.editBtn} onClick={onClick}>
        <span>{i18n.t('geo_name_validation_button_suggest_an_edit')}</span>
      </button>
    </div>
  );
}
