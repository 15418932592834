export const countryCodeToLanguage = {
  sg: ['en'],
  ph: ['en', 'tl'],
  id: ['id', 'en'],
  th: ['th'],
  my: ['en', 'ms'],
  vn: ['vi'],
  kh: ['km', 'en'],
  mm: ['my'],
};

export const languageCodeToTrans = {
  en: {
    en: 'English',
    zh: '英语',
    id: 'Bahasa Inggris',
    km: 'អង់គ្លេស',
    ms: 'Bahasa Inggeris',
    my: 'အင်္ဂလိပ်',
    th: 'ภาษาอังกฤษ',
    vi: 'Tiếng Anh',
  },
  id: {
    en: 'Bahasa Indonesia',
    zh: '印尼语',
    id: 'Bahasa Indonesia',
    km: 'ភាសាឥណ្ឌូនេស៊ី',
    ms: 'Bahasa Melayu',
    my: 'အင်္ဒိနေးရှားစကား',
    th: 'ภาษาอินโดนีเซีย',
    vi: 'Tiếng Indonesia',
  },
  km: {
    en: 'Khmer',
    zh: '高棉语',
    id: 'Bahasa Khmer',
    km: 'ខ្មែរ',
    ms: 'Bahasa Khmer',
    my: 'ခမာစကား',
    th: 'ภาษาเขมร',
    vi: 'Tiếng Khmer',
  },
  ms: {
    en: 'Bahasa Melayu',
    zh: '马来语',
    id: 'Bahasa Melayu',
    km: 'ភាសាម៉ាឡេ',
    ms: 'Bahasa Melayu',
    my: 'မလေးစကား',
    th: 'ภาษามาเลย์',
    vi: 'Tiếng Melayu',
  },
  my: {
    en: 'Burmese',
    zh: '缅甸语',
    id: 'Bahasa Myanmar',
    km: 'ភាសាភូមា',
    ms: 'Bahasa Burma',
    my: 'ဗမာ',
    th: 'ภาษาพม่า',
    vi: 'Tiếng Myanmar',
  },
  th: {
    en: 'Thai',
    zh: '泰语',
    id: 'Bahasa Thai',
    km: 'ថៃ',
    ms: 'Bahasa Thai',
    my: 'ထိုင်းစကား',
    th: 'ไทย',
    vi: 'Tiếng Thái',
  },
  vi: {
    en: 'Vietnamese',
    zh: '越南语',
    id: 'Bahasa Vietnam',
    km: 'វៀតណាម',
    ms: 'Bahasa Vietnam',
    my: 'ဗီယက်နမ်စကား',
    th: 'ภาษาเวียดนาม',
    vi: 'Tiếng Việt',
  },
  zh: {
    en: 'Chinese',
    zh: '汉语',
    id: 'Bahasa Cina',
    km: 'ភាសាចិន',
    ms: 'Bahasa Cina',
    my: 'တရုတ်စကား',
    th: 'ภาษาจีน',
    vi: 'Tiếng Trung',
  },
};
