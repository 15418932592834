import { hot } from 'react-hot-loader/root';
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { DotsFade as DotsFadeIcon } from '@components/icons';
import AppRoutes from './routes';
import ProtectedRoutes from './routes/ProtectedRoutes';

function App() {
  return (
    <BrowserRouter>
      <ProtectedRoutes>
        <Suspense fallback={<DotsFadeIcon style={{ width: 24 }} />}>
          <AppRoutes />
        </Suspense>
      </ProtectedRoutes>
    </BrowserRouter>
  );
}

export default hot(App);
