import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import './index.less';

export const SwiperSlide = ({ children, spaceBetween }) => (
  <div className="swiper-slide" style={{ marginRight: `${spaceBetween}px` }}>
    {children}
  </div>
);

const PaginationDots = ({ totalCount, activeIndex, style }) => (
  <div className="pagination-dots" style={style}>
    {Array.from({ length: totalCount }, (_, index) => (
      <div
        className={`dot ${activeIndex === index ? 'active' : ''}`}
        key={index}
      />
    ))}
  </div>
);

export const Swiper = ({
  pagination,
  extra,
  children,
  wrapperStyle,
  paginationStyle,
  spaceBetween = 16,
  onChange,
}) => {
  const [currentPage, setCurrentPage] = useState(0); // >= 0
  const [touchDeltaX, setTouchDeltaX] = useState(0);
  const swiperRef = useRef(null);
  const touchStartX = useRef(0);
  const touchStartY = useRef(0);

  const handleTouchMove = useCallback(event => {
    const deltaX = event.touches[0].clientX - touchStartX.current;
    const deltaY = event.touches[0].clientY - touchStartY.current;

    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      event.preventDefault();
      setTouchDeltaX(deltaX);
    }
  }, []);

  const handleTouchStart = useCallback(event => {
    touchStartX.current = event.touches[0].clientX;
    touchStartY.current = event.touches[0].clientY;
  }, []);

  useEffect(
    () => {
      if (children.length <= currentPage) {
        setCurrentPage(0);
      }
    },
    [children.length],
  );

  const handleTouchEnd = useCallback(
    event => {
      const screenLimit = window.innerWidth * 0.1;
      const touchDiff = touchDeltaX;

      if (touchDiff > screenLimit && currentPage > 0) {
        setCurrentPage(currentPage - 1);
      } else if (
        touchDiff < -screenLimit &&
        currentPage < children.length - 1
      ) {
        setCurrentPage(currentPage + 1);
      }
      requestAnimationFrame(() => {
        setTouchDeltaX(0);
      });
    },
    [touchDeltaX, currentPage, children],
  );

  useEffect(
    () => {
      onChange(currentPage);
    },
    [currentPage],
  );

  useEffect(
    () => {
      const swiperEl = swiperRef.current;

      if (swiperEl) {
        swiperEl.addEventListener('touchmove', handleTouchMove, {
          passive: false,
        });
        swiperEl.addEventListener('touchstart', handleTouchStart);
        swiperEl.addEventListener('touchend', handleTouchEnd);

        return () => {
          swiperEl.removeEventListener('touchmove', handleTouchMove);
          swiperEl.removeEventListener('touchstart', handleTouchStart);
          swiperEl.removeEventListener('touchend', handleTouchEnd);
        };
      }
      return () => {};
    },
    [swiperRef, handleTouchMove, handleTouchStart, handleTouchEnd],
  );

  const translateX = useMemo(
    () => {
      const slideWidth = swiperRef.current ? swiperRef.current.clientWidth : 0;
      return (
        currentPage * slideWidth + currentPage * spaceBetween - touchDeltaX
      );
    },
    [currentPage, swiperRef, touchDeltaX, spaceBetween],
  );

  return (
    <div className="new-swiper-container" ref={swiperRef} style={wrapperStyle}>
      {extra}
      <div
        className="new-swiper-wrapper"
        style={{
          transform: `translateX(${translateX * -1}px)`,
          willChange: 'transform',
          // transition: touchDeltaX === 0 ? 'transform 0.5s ease' : 'none',
        }}
      >
        {React.Children.map(children, child =>
          React.cloneElement(child, { spaceBetween }),
        )}
      </div>
      {pagination && (
        <PaginationDots
          totalCount={children.length}
          activeIndex={currentPage}
          style={paginationStyle}
        />
      )}
    </div>
  );
};
