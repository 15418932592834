import React from 'react';
import classNames from 'classnames';

import styles from './primary.less';

const PrimaryButton = props => {
  const { children, disabled, style, onClick } = props;
  return (
    <button
      className={classNames(styles.primaryBtn, { [styles.disabled]: disabled })}
      style={style}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
