// DebugButton.js
import React, { useState, useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

export default function DebugButton() {
  const [clickCount, setClickCount] = useState(0);

  useEffect(
    () => {
      let clickTimer = null;

      if (clickCount === 1) {
        clickTimer = setTimeout(() => {
          setClickCount(0);
        }, 1000);
      }

      if (clickCount >= 5) {
        clearTimeout(clickTimer);

        datadogRum.addAction('button_clicked_5_times_for_debug', {
          pageUrl: window.location.href,
        });

        setClickCount(0);
      }

      return () => {
        if (clickTimer) {
          clearTimeout(clickTimer);
        }
      };
    },
    [clickCount],
  );

  return (
    <button
      style={{
        position: 'fixed',
        width: 20,
        height: 20,
        backgroundColor: 'transparent',
        bottom: 0,
        left: 0,
        border: 0,
      }}
      onClick={() => setClickCount(prevCount => prevCount + 1)}
    />
  );
}
