// eslint-disable-next-line import/prefer-default-export
export function checkIfInternalPoi(poiId) {
  if (!poiId) {
    return false;
  }
  const regex = /^IT\./;
  return regex.test(poiId);
}

export const deserializeFormQuery = () => {
  try {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const result = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of params.entries()) {
      result[key] = value;
    }
    return result;
  } catch (error) {
    console.error('Error parsing query string:', error);
    return {};
  }
};
