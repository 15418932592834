import React from 'react';
import networkErrorSvg from 'public/images/network-error.svg';
import DebugButton from '@components/debug-button';
import { postPageState } from '@common/lib/jsBridge';

import styles from './index.less';

const defaultConfig = {
  image: networkErrorSvg,
  text: 'No connection found',
  subText: 'Check your network connection and try again.',
};

const defaultImageWith = 206;
const defaultImageHeight = 80;

export default function Exception(props) {
  const { config = {} } = props;
  const pageConfig = config ? { ...defaultConfig, ...config } : defaultConfig;

  const handleClick = () => {
    postPageState({ readyState: false });
    location.reload();
  };

  return (
    <div
      className={styles.exceptionContainer}
      data-testid="exception-container"
    >
      <img
        src={pageConfig.image}
        width={pageConfig.imageWidth || defaultImageWith}
        height={pageConfig.imageHeight || defaultImageHeight}
        alt="page-error"
        data-testid="error-image"
      />
      <div data-testid="title" className={styles.title}>
        {pageConfig.text}
      </div>
      <div data-testid="sub-title" className={styles.subTitle}>
        {pageConfig.subText}
      </div>
      {!pageConfig.isHiddenTryButton && (
        <a
          className={styles.link}
          onClick={handleClick}
          onKeyDown={() => {}}
          role="button"
          tabIndex="0"
        >
          try again
        </a>
      )}
      <DebugButton />
    </div>
  );
}
