import { useState } from 'react';
import { showPageBottomBar, setPageTopBarStyle } from '@common/lib/jsBridge';

const useAsyncModal = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openModal = async () => {
    setIsLoading(true);
    try {
      await showPageBottomBar({ needShow: false });
      await setPageTopBarStyle({ style: 1 });
    } catch (error) {
      console.log(error);
    }
    setModalVisible(true);
    // setIsLoading(false);
  };

  const closeModal = async () => {
    setIsLoading(true);
    setModalVisible(false);

    await new Promise(resolve => setTimeout(resolve, 200));

    try {
      await setPageTopBarStyle({ style: 0 });
      await showPageBottomBar({ needShow: true });
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  return {
    modalVisible,
    isLoading,
    openModal,
    closeModal,
  };
};

export default useAsyncModal;
