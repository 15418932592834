import { savedPlaceLabelConfig } from './constant';
import { countryCodeToLanguage } from './countryAndLanguage';

export const buildParams = (data, requestedId, languageCode, latlng) => {
  // reference: This parameter is currently only useful in debugging; location is obtained via jsBridge method.
  const { reference } = data;
  const { latitude, longitude } = latlng || {};

  const params = {
    requested_ids: requestedId,
    language: languageCode,
    source_app: data.source_app,
    transportation_type: data.transportation_type || 0,
    vehicle_type: data.vehicle_type,
    target_city_id: data.target_city_id,
    greyout_poi: data.greyout_poi,
    navigation_point: true,
  };

  if (reference || (latlng && latlng.latitude && latlng.longitude)) {
    Object.assign(params, {
      reference: reference || `${latitude},${longitude}`,
    });
  }

  return params;
};

export const formatCombinedAddress = ({ address, distance, hasLatlng }) => {
  const components = [];

  const distanceValue = parseFloat(distance);
  if (hasLatlng && !isNaN(distanceValue)) {
    components.push(`${distanceValue} km`);
  }

  if (address) {
    components.push(address);
  }

  return components.join(' • ');
};

function getNativeName(nativeNames, countryCode, languageCode, name) {
  if (!nativeNames) return '';
  const nativeLanguageList = countryCodeToLanguage[countryCode] || [];
  const nativeCode = nativeLanguageList.find(i => i !== languageCode);
  if (nativeNames[nativeCode] === name) {
    return '';
  }
  return nativeNames[nativeCode];
}

function determineFallbackLanguageCode(
  nativeShortName,
  specificLanguageNames,
  currentLanguageCode,
) {
  const nativeShortNameExists =
    nativeShortName && nativeShortName[currentLanguageCode];
  const nativeNameExists =
    specificLanguageNames && specificLanguageNames[currentLanguageCode];

  if (nativeShortNameExists || nativeNameExists) {
    return null;
  }

  // Fallback to English ('en') if specific language code doesn't exist
  return 'en';
}

const getParentIDFromAreasFeild = (res, currPoiId) => {
  const { renders = [], areas = [] } = res || {};
  const renderItem = renders && renders.find(i => i.id === currPoiId);
  if (renderItem) {
    return { isRoot: true };
  }

  const parentItem =
    areas &&
    areas.find(
      ({ plots }) => plots && plots.find(({ id }) => id === currPoiId),
    );

  return {
    isRoot: false,
    rootPoiId: parentItem && parentItem.id,
  };
};

const getPoiNameAndParentNameWithNavigationPoint = (
  currPoiDetail,
  res,
  languageCode,
) => {
  const { result = [] } = res;
  const {
    address = {},
    short_name_native: shortNameNative,
    id: currPoiId,
  } = currPoiDetail;
  const { specific_names: specificNames } = address;
  const name = (specificNames && specificNames[languageCode]) || address.name;
  const shortName =
    (shortNameNative && shortNameNative[languageCode]) ||
    currPoiDetail.short_name;

  const fallbackLanguageCode = determineFallbackLanguageCode(
    shortNameNative,
    specificNames,
    languageCode,
  );
  const transName = getNativeName(
    specificNames,
    address.country_code,
    fallbackLanguageCode || languageCode,
    name,
  );

  const { isRoot, rootPoiId } = getParentIDFromAreasFeild(res, currPoiId);
  // if renders containers currPoiId
  if (isRoot) {
    return { name, transName, parentName: null };
  }
  const parent = result.find(i => i.id === rootPoiId);
  const parentName = parent && parent.address && parent.address.name;
  const transShortName = getNativeName(
    shortNameNative,
    address.country_code,
    fallbackLanguageCode || languageCode,
    shortName,
  );

  if (!shortName) {
    return {
      name: name || '-',
      transName,
      parentName,
    };
  }

  return {
    name: shortName || '-',
    transName: transShortName,
    parentName,
  };
};

const translateSavedPlaceLabel = (label, languageCode) => {
  let savedPlaceLabel = label;
  if (['HOME_TAG_PRESET', 'WORK_TAG_PRESET'].includes(label)) {
    const config = savedPlaceLabelConfig[label];
    savedPlaceLabel = config[languageCode] || config.en;
  }
  return savedPlaceLabel;
};

export const extractPoiMetaData = (res, poiId) => {
  if (!res) return null;

  const { result = [] } = res;
  const poiDetail = (result && result.find(i => i.id === poiId)) || {};
  return poiDetail.metadata;
};

export const formatLookupReponse = (poiId, res, languageCode, hasLatlng) => {
  if (!res) return null;

  const { uuid, result = [] } = res;
  const poiDetail = (result && result.find(i => i.id === poiId)) || {};
  const { address = {}, label } = poiDetail;

  // if it's a child poi, should get parent poi name
  const {
    name,
    parentName,
    transName,
  } = getPoiNameAndParentNameWithNavigationPoint(poiDetail, res, languageCode);

  // trans label(Home, Work)
  const savedPlaceLabel = translateSavedPlaceLabel(label, languageCode);

  const combinedAddress = formatCombinedAddress({
    address: address.combined_address,
    distance: poiDetail.distance_between,
    hasLatlng,
  });

  return {
    poiId,
    name,
    parentName,
    transName,
    combinedAddress,
    label: savedPlaceLabel,
    tags: poiDetail.tags || [],
    latlng: poiDetail.latlng,
    addressData: address
      ? {
          ...address,
          latlng: poiDetail.latlng,
        }
      : { latlng: poiDetail.latlng },
    uuid, // for tracking data
  };
};

function getPlotsTags(poiId, res) {
  const { areas = [] } = res || {};
  const { isRoot, rootPoiId } = getParentIDFromAreasFeild(res, poiId);
  try {
    if (!isRoot) {
      const areasItem = (areas && areas.find(i => i.id === rootPoiId)) || {};
      const plots = areasItem.plots
        ? areasItem.plots.find(i => i.id === poiId)
        : {};
      return plots.tags;
    }
  } catch (err) {
    console.error('getPlotsTags error:', err);
  }
  return null;
}

export const formatPoiTags = (poiId, res) => {
  if (!res) return null;

  const { result = [] } = res;
  const poiDetail = (result && result.find(i => i.id === poiId)) || {};
  const plotsTags = getPlotsTags(poiId, res) || [];
  const combinedTags = [...(poiDetail.tags || []), ...plotsTags];
  const tagsWithNonNullText = combinedTags.filter(item => item.text);
  const filteredTags = tagsWithNonNullText.filter(item => item.code < 500);
  return filteredTags;
};

export const buildTrackingData = (poiDetail, metadata) => {
  try {
    const { tags = [], favorite } = poiDetail || {};
    const { labelNames, labelIds } = tags.reduce(
      (pre, item) => {
        const { code, text } = item;
        return {
          labelNames: pre.labelNames.push(text),
          labelIds: pre.labelIds.push(code),
        };
      },
      {
        labelNames: [],
        labelIds: [],
      },
    );

    const loadEventData = {
      POI_ENDPOINT: metadata && metadata.api,
      POI_LABEL_NAME: labelNames.join(','),
      POI_LABEL_ID: labelIds.join(','),
      IS_SAVED_PLACE: favorite,
    };
    return loadEventData;
  } catch (error) {
    return {};
  }
};
