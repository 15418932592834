import { savedPlaceLabelConfig } from '../../helper/constant';

/* eslint-disable no-plusplus */
const containsHttpOrHttps = url => /(http|https):\//.test(url);

const containsConsecutiveSpecialCharacters = str => {
  const specialChars = /[~!@#$%^&(){}[\]<>?/]{3,}/g;
  return specialChars.test(str);
};

const ERROR_MESSAGE = {
  unSupported: 'You can’t include any special characters here.',
  urlNotAllowed: 'You can’t include any URLs here.',
};

export const validateName = str => {
  if (containsHttpOrHttps(str)) {
    return ERROR_MESSAGE.urlNotAllowed;
  }

  if (containsConsecutiveSpecialCharacters(str)) {
    return ERROR_MESSAGE.unSupported;
  }

  return null;
};

export const isNameValid = str => !validateName(str);

export const trigramSimilarityScore = (s1, s2) => {
  if (!s1 || !s2 || s1.length < 3 || s2.length < 3) {
    // throw new Error('Input strings should be at least 3 characters long.');
    return 1;
  }

  function trigrams(s) {
    const trigramSet = new Set();
    for (let i = 0; i < s.length - 2; i++) {
      trigramSet.add(s.substring(i, i + 3));
    }
    return trigramSet;
  }

  try {
    const trigramSet1 = trigrams(s1);
    const trigramSet2 = trigrams(s2);
    let intersectionSize = 0;
    trigramSet1.forEach(trigram => {
      if (trigramSet2.has(trigram)) intersectionSize++;
    });
    return (
      intersectionSize /
      (trigramSet1.size + trigramSet2.size - intersectionSize)
    );
  } catch (e) {
    console.error('Error in trigramSimilarityScore:', e);
    return 1;
  }
};

function flattenConfig(config) {
  const values = Object.values(config);
  return [].concat(...values.map(Object.values));
}

export const containsKeyword = newName => {
  const flattenedConfigArray = flattenConfig(savedPlaceLabelConfig);
  // eslint-disable-next-line no-restricted-syntax
  for (const keyword of flattenedConfigArray) {
    const regex = new RegExp(`\\b${keyword}\\b`, 'gi');
    if (regex.test(newName)) {
      return keyword;
    }
  }
  return null;
};
