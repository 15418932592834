import { useMemo } from 'react';
import { checkIfInternalPoi } from '../helper/index';
import { feedbackCategory } from '../helper/constant';

const useConditions = (reviewStatusAndNudge, isChildPoi, poiId) => {
  const conditions = useMemo(
    () => {
      const {
        nameReviewStatus = null,
        shortNameReviewStatus = null,
        addressReviewStatus = null,
        nudges = [],
      } =
        reviewStatusAndNudge || {};

      const isInternalPoi = checkIfInternalPoi(poiId);
      if (!isInternalPoi) {
        return {
          showNameWarning: false,
          showLocationWarning: false,
          showNameInReview: false,
          showAddressInReview: false,
          showNudges: false,
          showEditButton: false,
        };
      }

      const locationWarning =
        addressReviewStatus !== 0 &&
        nudges.some(i => i.category === feedbackCategory.SUSPICIOUS_LATLNG);
      const showNudges = nudges.length > 0;
      const showNameInReview = isChildPoi
        ? shortNameReviewStatus === 0
        : nameReviewStatus === 0;

      return {
        showLocationWarning: locationWarning,
        showNameInReview,
        showAddressInReview: addressReviewStatus === 0,
        showNudges,
        showEditButton: true,
      };
    },
    [reviewStatusAndNudge, isChildPoi],
  );

  return conditions;
};

export default useConditions;
