import { useState } from 'react';
import { javisSubmissionSubmit } from '@endpoints/javis';

const formatRequestDataToFeedback = (poiId, feedbackData) => {
  const { question, category, userOption } = feedbackData;
  return {
    submission_type: 6,
    additional_data: {
      poi_id: poiId,
      feedback: {
        question_id: category,
        question_title: question,
        user_option: userOption,
      },
    },
    input: {},
  };
};

const formatRequestDataToEditPoiName = (poiId, poiName, isChildpoi) => {
  const input = isChildpoi
    ? { poi: { short_name: poiName } }
    : { poi: { name: poiName } };
  return {
    submission_type: 6,
    additional_data: { poi_id: poiId },
    input,
  };
};

export default function useSubmissionSubmit(params = {}) {
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState({});

  const postJavisSubmit = async reqData => {
    setLoading(true);

    let submissioRes = {};
    try {
      submissioRes = await javisSubmissionSubmit(reqData);
    } catch (err) {
      // handle error
    } finally {
      setLoading(false);
    }
    return submissioRes;
  };

  const postFeedback = async feedbackData => {
    const reqData = formatRequestDataToFeedback(params.poiId, feedbackData);
    const responseData = await postJavisSubmit(reqData);
    setRes(responseData);
    return responseData;
  };

  const postEditPoiName = async (poiName, isChildpoi) => {
    const reqData = formatRequestDataToEditPoiName(
      params.poiId,
      poiName,
      isChildpoi,
    );
    const responseData = await postJavisSubmit(reqData);
    setRes(responseData);
    return responseData;
  };

  return { postFeedback, postEditPoiName, data: res, loading };
}
