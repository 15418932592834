import React, { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';
import loadingJson from 'public/images/loading.json';
import styles from './index.less';

function Spinner() {
  const [spinnerAnimation, setSpinnerAnimation] = useState(null);
  const spinnerRef = useRef(null);

  useEffect(() => {
    (async () => {
      const animation = lottie.loadAnimation({
        container: spinnerRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: loadingJson,
        name: 'spinner',
      });

      animation.goToAndPlay(104, true);
      setSpinnerAnimation(animation);
    })();

    return () => {
      if (spinnerAnimation) {
        spinnerAnimation.destroy();
      }
    };
  }, []);

  return (
    <div
      className={styles.lottieLoading}
      ref={spinnerRef}
      style={{ width: 88, height: 88 }}
    />
  );
}

export default Spinner;
