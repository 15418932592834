import { useEffect } from 'react';
import useTrackingStore from '@stores/tracking';
import { trackEventForPoiDetail } from '@common/lib/jsBridge';

const useTrackingDataForPoiDetail = (
  searchParamsObj,
  requestFeedbackAndJarvisDone,
  conditions,
) => {
  const { loadEventData } = useTrackingStore();

  useEffect(() => {
    if (searchParamsObj && searchParamsObj.javisSubmitSuccess > 0) {
      trackEventForPoiDetail({
        name: 'EDIT_SUBMISSION_TOAST_LOAD',
        params: {
          SUBMISSION_STATUS: 'SUCCESS',
          EDIT_FIELD: 'ADDRESS',
          SUBMISSION_ID: null,
        },
      });
    }

    trackEventForPoiDetail({
      name: 'POI_DETAILS_PAGE_VIEW',
      params: {
        STATE_NAME: 'PLACE_DETAILS',
        URL: location.href,
      },
    });
  }, []);

  useEffect(
    () => {
      if (requestFeedbackAndJarvisDone && conditions && loadEventData) {
        try {
          const warnings = [
            { key: 'name', value: conditions.showNameWarning },
            { key: 'address', value: conditions.showLocationWarning },
          ];
          const SUGGEST_EDIT_PROMPT =
            warnings
              .filter(({ value }) => value === true)
              .map(({ key }) => key)
              .join(',') || null;
          trackEventForPoiDetail({
            name: 'POI_DETAILS_LOAD',
            params: {
              ...loadEventData,
              SUGGEST_EDIT_PROMPT,
              STATE_NAME: 'PLACE_DETAILS',
            },
          });
        } catch (err) {
          console.error('Error tracking POI details load:', err);
        }
      }
    },
    [requestFeedbackAndJarvisDone, conditions, loadEventData],
  );
};

export default useTrackingDataForPoiDetail;
