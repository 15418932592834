import React from 'react';
import classNames from 'classnames';
import styles from './detailItem.less';

const DetailItem = ({ children, label, borderBottom, icon, extra }) => (
  <div
    className={classNames(
      styles.detailItem,
      borderBottom !== 0 ? 'device-border-bottom' : '',
    )}
  >
    <div className={styles.left}>
      <span className={styles.icon}>{icon}</span>
      {label ? <span className="line-clamp">{label}</span> : children}
    </div>
    {extra && <React.Fragment>{extra}</React.Fragment>}
  </div>
);

export default DetailItem;
