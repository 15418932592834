import React from 'react';
import { render } from 'react-dom';
import I18n from 'i18n-js';
// import VConsole from 'vconsole';
import { datadogRum } from '@datadog/browser-rum';
import { isPrd } from '@common/utils/env';
import { getCurrentLanguageCode } from '@common/lib/jsBridge';
import { datadogRumConfigStg, datadogRumConfigPrd } from './config';

import App from './App';

// eslint-disable-next-line import/first
import './global.less';

// eslint-disable-next-line no-new
// new VConsole();

window.Grab = {
  config: {},
  router: {},
  locale: 'en',
};
const LOCALES = ['en', 'id', 'km', 'ms', 'my', 'th', 'vi', 'zh'];

const renderApp = () => {
  render(<App />, document.getElementById('root'));
};

const getFetchLocaleJson = async () => {
  const languageCode = await getCurrentLanguageCode();
  let locale = 'en';
  if (LOCALES.includes(languageCode)) {
    locale = languageCode;
  }
  window.Grab.locale = locale;
  return window.fetch(require(`public/locales/${locale}.json`));
};

getFetchLocaleJson()
  .then(async responses => {
    const localeResponse = responses;

    I18n.translations[window.Grab.locale] = await localeResponse.json();
    I18n.locale = window.Grab.locale;

    const datadogRumConfig = isPrd()
      ? datadogRumConfigPrd
      : datadogRumConfigStg;

    datadogRum.init({
      ...datadogRumConfig,
      version: process.env.APP_VERSION,
      sessionSampleRate: 90,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      beforeSend: event => {
        if (event.type === 'error') {
          if (event.error && event.error.type === 'IgnoredEventCancel') {
            return false;
          }
          // this error came from the mapbox-gl, we can ignore it
          if (event.error && event.error.message === 'Load failed') {
            return false;
          }
        }
        return true;
      },
    });

    if (!isPrd()) {
      datadogRum.startSessionReplayRecording();
    }

    renderApp();
  })
  .catch(err => {
    console.error('init error:', err);
  });
