import { useEffect, useState } from 'react';

const useCustomNavigate = () => {
  const [navigateData, setNavigateData] = useState({});
  const [navigating, setNavigating] = useState(false);

  useEffect(
    () => {
      if (navigateData.url) {
        setNavigating(true);
        if (navigateData.callback) {
          navigateData.callback();
        }
        if (navigateData.replace) {
          location.replace(navigateData.url);
        } else {
          location.href = navigateData.url;
        }
      }
    },
    [navigateData],
  );

  return {
    navigating,
    setNavigateData,
  };
};

export default useCustomNavigate;
