export const datadogRumConfigStg = {
  applicationId: '55e5597d-b89d-4961-a224-3fde09c49d1e',
  clientToken: 'pub7a9cd88640e9ae4043d8e0e96e374e4a',
  site: 'datadoghq.com',
  service: 'pax-poi-webapp',
  env: 'staging',
};

export const datadogRumConfigPrd = {
  applicationId: 'eb4c36a5-ff9b-410f-807e-baa503db91fc',
  clientToken: 'pub85ccf0644dd119e3538ae1e614a0597e',
  site: 'datadoghq.com',
  service: 'pax-poi-webapp-prod',
  env: 'production',
};
