import { create } from 'zustand';

const useUserStore = create(set => ({
  token: '',
  gpsLocation: null,
  setToken: token => set({ token }),
  setGpsLocation: gpsLocation => set({ gpsLocation }),
}));

export default useUserStore;
