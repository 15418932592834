import axios from 'axios';
import useUserStore from '../../stores/user';
import { getAccessToken } from '../lib/jsBridge';

const UNKNOWN_ERROR_MESSAGE = 'Unknown network error!';
const AXIOS_TIMEOUT = 60 * 1000;

const axiosInstance = axios.create({
  timeout: AXIOS_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  async config => {
    const newConfig = { ...config };
    let { token } = useUserStore.getState();
    try {
      if (!token) {
        const { accessToken = '' } = await getAccessToken();
        token = accessToken;
      }
      const regex = /^https?:\/\/p\..*\.com/;
      if (regex.test(newConfig.url)) {
        newConfig.headers['X-MTS-SSID'] = token;
      } else {
        newConfig.headers.Authorization = token;
      }
    } catch (err) {
      console.error('[Error] request:', err);
    }

    return newConfig;
  },
  error => {
    Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  response => response.data,
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('accessToken');
    }
    return Promise.reject(error);
  },
);

/**
 * Some common exceptions that may encounter:
 * Network Error: This error occurs if the network request hasn't been completed. Common causes include an incorrect URL, network connectivity issues, or a server not being able to handle the request. It is possible that you need to check your internet connectivity, the request URL, or even the backend server that you're trying to reach.
 * Timeout Error: This error occurs if the service takes too long to respond. A slow response may be caused by a backend loading too much data or network connectivity issues. It may be necessary to adjust the request timeout limit, optimize API endpoints, or diagnose network stability issues.
 * Response Error: These errors are caused by some issues receiving the response from the server. Examples of common statuses that cause this type of error include 400, 401, 404, 500, and 503. It is necessary to investigate why the server is giving a response status error to avoid further issues with the application.
 * Cancel Error: This error occurs when you have canceled the request via an abort controller such as a CancelToken. If you don't want to abort the request, you can catch this and handle the error.
 * Others: Some undefined exceptions might occur during network errors or response parsing. Alternatively, you may face CORS issues while making a request from different domains, and browsers will throw an error.
 * @param {*} error AxiosError
 * @param {*} url
 * @returns
 */
function getFromHeaders(headers) {
  if (!headers) {
    return {};
  }
  try {
    const result = {
      'Authorization Length':
        headers.Authorization && headers.Authorization.length,
      'X-MTS-SSID Length':
        headers['X-MTS-SSID'] && headers['X-MTS-SSID'].length,
    };
    return result;
  } catch (err) {
    return {};
  }
}
function handleError(error, url) {
  /* eslint-disable prettier/prettier */
  window.console.warn(`[ERROR] HTTP Request Error
    URI: ${url},
    Status: ${error.response ? error.response.status : 0},
    Message: ${error.message},
    ${error.response ? `Response Data: ${JSON.stringify(error.response.data)}` : ''}
    ${error.config ? `Request Headers: ${JSON.stringify(getFromHeaders(error.config.headers))}` : ''}
  `);
  return {
    status: error.response ? error.response.status : 0, // 0: Network Error, Timeout Error, Cancel Error, CORS...
    data: error.response && error.response.data ? error.response.data : {},
    error: error.message || UNKNOWN_ERROR_MESSAGE,
  };
}

export async function get(url, params = {}, options = {}) {
  try {
    return await axiosInstance.get(url, { params, ...options });
  } catch (err) {
    return handleError(err, url);
  }
}

export async function post(url, data = {}, options = {}) {
  try {
    return await axiosInstance.post(url, data, {
      ...options,
    });
  } catch (err) {
    return handleError(err, url);
  }
}