import React, { useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import I18n from 'i18n-js';
import { CSSTransition } from 'preact-transitioning';

import { BackIcon } from '@components/icons';
import Input from '@components/input';
import PrimaryButton from '@components/button/primary';
import { useConfirmationDialog } from '@components/confirm-dialog';
import { trackEventForPoiDetail } from '@common/lib/jsBridge';
import * as message from '@components/message/bootstrap';
import {
  validateName,
  trigramSimilarityScore,
  containsKeyword,
} from './helper';
import { getMessageContext } from '../../helper/constant';
import useSubmissionSubmit from '../../hooks/useJarvisSubmission';

import './index.css';

const Modal = props => {
  const { visible, name } = props;
  const [error, setError] = useState(null);
  const [newName, setNewName] = useState(name);
  const { confirm, ConfirmationDialogPortal } = useConfirmationDialog();
  const { postEditPoiName, loading: submitLoading } = useSubmissionSubmit({
    poiId: props.poiId,
  });

  const isDisabled = useMemo(
    () => {
      if (!newName || newName === name || error) {
        return true;
      }
      if (submitLoading) {
        return true;
      }
      return false;
    },
    [newName, name, error, submitLoading],
  );

  const handleBack = () => {
    props.onClose();
    setNewName('');
    setError('');
  };

  const handleChange = value => {
    const errMsg = validateName(value);
    setError(errMsg);
    setNewName(value);
  };

  const postData = async () => {
    const locale = window.Grab.locale;
    const res = await postEditPoiName(
      {
        [locale || 'en']: newName,
      },
      props.isChildPoi,
    );

    const ifSuccess = res && res.status_code === 0;

    try {
      trackEventForPoiDetail({
        name: 'EDIT_PLACE_NAME_SUBMIT',
      });
      trackEventForPoiDetail({
        name: 'EDIT_SUBMISSION_TOAST_LOAD',
        params: {
          SUBMISSION_STATUS: ifSuccess ? 'SUCCESS' : 'FAIL',
          EDIT_FIELD: 'NAME',
          SUBMISSION_ID: ifSuccess ? res.data.output.submission_id : null,
        },
      });
    } catch (err) {
      // handle error for track data failed.
    }

    if (ifSuccess) {
      props.onSubmitSuccess();
    } else {
      message.error({
        content: getMessageContext('error'),
        styles: {
          paddingTop: '50px',
        },
      });
    }
    return ifSuccess;
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = () => {
    if (isDisabled) {
      return;
    }
    // 1. check low similarity score
    const score = trigramSimilarityScore(name, newName);
    if (score < 0.1) {
      trackEventForPoiDetail({ name: 'EDIT_PLACE_NAME_SUBMIT_LOAD' });
      const VALIDATION_HEADING_NAME_CHANGE_TO = I18n.t(
        'geo_name_validation_heading_name_change_to',
      );
      const VALIDATION_BODY_NAME_CHANGE_TO = I18n.t(
        'geo_name_validation_body_name_change_to',
      );
      confirm(
        VALIDATION_HEADING_NAME_CHANGE_TO.replace('%s', newName),
        VALIDATION_BODY_NAME_CHANGE_TO.replace('%s', name),
        () => {
          postData();
          trackEventForPoiDetail({ name: 'EDIT_PLACE_NAME_SUBMIT_YES' });
        },
        () => {
          trackEventForPoiDetail({ name: 'EDIT_PLACE_NAME_SUBMIT_NO' });
        },
      );
      return;
    }
    // 2. new name contains keywords (home,work)
    const keyword = containsKeyword(newName);
    if (keyword) {
      trackEventForPoiDetail({ name: 'EDIT_PLACE_NAME_SUBMIT_LOAD' });
      const VALIDATION_HEADING_ACTUAL_NAME_CONTAIN = I18n.t(
        'geo_name_validation_heading_actual_name_contain',
      );
      const VALIDATION_BODY_ACTUAL_NAME_CONTAIN = I18n.t(
        'geo_name_validation_body_actual_name_contain',
      );
      confirm(
        VALIDATION_HEADING_ACTUAL_NAME_CONTAIN.replace('%s', keyword),
        VALIDATION_BODY_ACTUAL_NAME_CONTAIN.replace('%s', keyword),
        () => {
          postData();
          trackEventForPoiDetail({ name: 'EDIT_PLACE_NAME_SUBMIT_YES' });
        },
        () => {
          trackEventForPoiDetail({ name: 'EDIT_PLACE_NAME_SUBMIT_NO' });
        },
      );
      return;
    }

    postData();

    // track Data
    trackEventForPoiDetail({
      name: 'EDIT_PLACE_NAME_SAVE',
    });
  };

  return ReactDOM.createPortal(
    <CSSTransition
      in={visible}
      timeout={500}
      classNames="edit_poi_name-modal"
      unmountOnExit
    >
      <div className="edit_poi_name-modal">
        <div className="header">
          <button className="icon-btn back-icon" onClick={handleBack}>
            <BackIcon />
          </button>
          <span className="title">
            {I18n.t('geo_name_validation_heading_edit_name')}
          </span>
        </div>
        <div className="content">
          <Input
            defaultValue={name}
            onChange={handleChange}
            status={error ? 'error' : ''}
          />
          {error && <div className="err-msg">{error}</div>}
        </div>
        <div className="footer">
          <PrimaryButton onClick={handleSubmit} disabled={isDisabled}>
            Save
          </PrimaryButton>
        </div>
        {ConfirmationDialogPortal}
      </div>
    </CSSTransition>,
    document.body,
  );
};

export default Modal;
