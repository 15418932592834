import React from 'react';
import i18n from 'i18n-js';
import { WarningIcon } from '@components/icons';

export default function WarningTip() {
  return (
    <div className="warning-line">
      <WarningIcon className="warning-icon" />
      <span>
        {i18n.t('geo_places_search_revamp_tooltip_suggest_edit_dropoff')}
      </span>
    </div>
  );
}
