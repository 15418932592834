import { get, post } from '@common/utils/service';
import { isPrd } from '@common/utils/env';

const getBaseUrl = () => {
  const POI_HOST = isPrd()
    ? 'https://api.grab.com'
    : 'https://api.stg-myteksi.com';
  return `${POI_HOST}/karta/crowdsourcing/v2`;
};

const BASE_URL = getBaseUrl();

export const javisSubmissionList = async data => {
  const uri = `${BASE_URL}/submission/list`;
  try {
    const res = await get(uri, data);
    if (res.status_code > 0) {
      // report error
    }
    return res.data;
  } catch (error) {
    return null;
  }
};

export const javisSubmissionSubmit = async data => {
  const uri = `${BASE_URL}/submission/submit`;
  const res = await post(uri, data);
  return res;
};
