import React from 'react';
import { TagWarning, TagError, TagPositive } from '@components/icons';

const getTypeFromCode = code => {
  if (code < 200) {
    return 'positive';
  } else if (code < 300) {
    return 'warning';
  } else if (code < 500) {
    return 'error';
  }
  return '';
};

const tagsConfig = {
  warning: <TagWarning className="tag-icon" />,
  error: <TagError className="tag-icon" />,
  positive: <TagPositive className="tag-icon" />,
};

export default function Tags(props) {
  const { code, text } = props;

  return (
    <span className="tags-item">
      {tagsConfig[getTypeFromCode(code)]}
      <span>{text}</span>
    </span>
  );
}
