import { useEffect, useState } from 'react';
import { getPoiFeedbackPrompt } from '@endpoints/poi';
import { javisSubmissionList } from '@endpoints/javis';
import { checkIfInternalPoi } from '../helper';
import { processReviewStatusAndNudges } from '../helper/feedackAndJarvis';

// Handle feedback propmt and javis result
export default function useFeedackAndJarvis(params) {
  const [loading, setLoading] = useState(false);
  const [requestDone, setRequestDone] = useState(false);
  const [feedbackUuid, setFeedbackUUid] = useState('');
  const [reviewStatusAndNudge, setReviewStatusAndNudge] = useState({});

  const getDataFromHeuAndjavis = async () => {
    setLoading(true);

    let res = [];
    try {
      res = await Promise.all([
        getPoiFeedbackPrompt({ poiId: params.poiId }),
        javisSubmissionList({
          submission_type: 6,
          query_param: { poi_id: params.poiId },
        }),
      ]);
    } catch (error) {
      console.log('getDataFromHeuAndjavis error:', error);
    } finally {
      setLoading(false);
      setRequestDone(true);
    }

    const [feedbackPrompt, submissionList] = res;
    const { prompts, uuid } = feedbackPrompt || {};
    const { submissions } = submissionList || {};

    setFeedbackUUid(uuid);

    try {
      const data = processReviewStatusAndNudges({
        submissions,
        prompts,
        options: {
          locale: window.Grab.locale,
        },
      });
      setReviewStatusAndNudge(data);
      return data;
    } catch (error) {
      return {};
    }
  };

  const refreshFeedackAndJarvis = () => {
    getDataFromHeuAndjavis();
  };

  useEffect(() => {
    const isInternalPoi = checkIfInternalPoi(params.poiId);
    if (isInternalPoi) {
      getDataFromHeuAndjavis();
    }

    return () => {};
  }, []);

  return {
    feedbackUuid,
    reviewStatusAndNudge,
    loading,
    requestDone,
    refreshFeedackAndJarvis,
  };
}
